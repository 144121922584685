@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --dark-blue: #0878FF;
  --light-blue: #E7F6FD;
}

@font-face {
  font-family: "Satoshi-Variable";
  src: local("Satoshi-Variable"), url(./fonts/Satoshi-Variable.ttf) format('opentype');
}
@font-face {
  font-family: "Satoshi-VariableItalic";
  src: local("Satoshi-VariableItalic"), url(./fonts/Satoshi-VariableItalic.ttf) format('opentype');
}
@font-face {
  font-family: "Gambetta-Variable";
  src: local("Gambetta-Variable"), url(./fonts/Gambetta-Variable.ttf) format('opentype');
}
@font-face {
  font-family: "Gambetta-VariableItalic";
  src: local("Gambetta-VariableItalic"), url(./fonts/Gambetta-VariableItalic.ttf) format('opentype');
}

html{
  scroll-behavior: smooth;
  background-color: #0c0c0c;
}

